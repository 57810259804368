<template>
  <nav class="navbar container" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <router-link to="/" >
        <figure class="image is-128x128">
          <img src="@/assets/cnc.jpg">
        </figure>
      </router-link>
      <a role="button" class="navbar-burger burger" aria-label="menu" aria-expanded="false"
         data-target="navbarBasicExample" @click="isOpen = !isOpen" v-bind:class="{'is-active': isOpen}">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
    <div id="navbar" class="navbar-menu" :class="{'is-active': isOpen}">
      <div class="navbar-start" @click="isOpen = !isOpen">
        <router-link to="/sange" class="navbar-item">Sange</router-link>
        <router-link to="/salmer" class="navbar-item">Salmer</router-link>
        <router-link to="/oplysninger" class="navbar-item">Oplysninger</router-link>
      </div>
      <div class="navbar-end" v-if="userType != null">
        <div class="navbar-item">
          <div class="buttons">
            <button type="button" class="button is-dark" @click="logOut">
              <strong>Sair</strong>
            </button>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  name: 'Navigation',
  data: () => ({
    isOpen: false,
  }),
  props:{
    userType:{
      type:String,
    },
  },
  methods:{
    logOut(){
      localStorage.removeItem('adminToken');
      console.log(this.$route.name);
      if(this.$route.name !== 'home'){
        this.$router.push('/')
      }else{
        location.reload();
      }
    }
  },
}
</script>
<style lang="scss" scoped>
nav {
  margin-top: 25px;
  margin-bottom: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #ff0000;
      background: none;
    }
  }
}
.navbar-item:hover{
  background: none;
  color: #ad0000;
}
.navbar-start{
  margin-left: 20px;
}
</style>
<template>
  <div id="app">
    <vue-headful
        title="Haneropstanden"
        description="Chants af neocatechumenal måde"
    />
    <Navigation />
    <router-view/>
    <Footer />
  </div>
</template>
<script>
import Navigation from "./components/partials/Navigation";
export default {
  name: 'app',
  components: {Navigation},
  methods:{

  },
}
</script>
<style lang="scss">
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
<template>
  <section class="central-menu">
    <div class="tile is-ancestor">
      <div class="tile is-parent has-text-centered is-4 is-vertical">
        <div class="tile is-child box"  v-on:click="$router.push('/sange')">
          <p class="title has-text-white">Sange</p>
          <p class="subtitle has-text-white">Sange fra den<br/>Neokatekumenale Vandring</p>
        </div>
      </div>
      <div class="tile is-parent has-text-centered is-4 is-vertical">
        <div class="tile is-child box"  v-on:click="$router.push('/salmer')">
          <p class="title has-text-white">Salmer</p>
          <p class="subtitle has-text-white">Akkorder til<br/>salmer</p>
        </div>
      </div>
      <div class="tile is-parent has-text-centered is-4 is-vertical">
        <div class="tile is-child box"  v-on:click="$router.push('/oplysninger')">
          <p class="title has-text-white">Oplysninger</p>
          <p class="subtitle has-text-white">Generelle oplysinger om<br/>Den Neokatekumenale Vandring</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HomeInfo"
}
</script>

<style scoped>
@media screen and (min-width: 800px) {
  .central-menu{
    width: 60%;
    margin: 40px auto 20px auto;
  }
}
@media screen and (max-width: 800px) {
  .central-menu{
    width: 90%;
    margin: 40px auto 20px auto;
  }
}
.title{
  padding-top: 20px;
}
.subtitle{
  padding-top: 40px;
  padding-bottom: 40px;
}
.box{
  background-color: #ad0000;
}
.box:hover{
  background-color: #ff0000;
  cursor: pointer;
}
</style>